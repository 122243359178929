import React, { useState } from "react"


//On importre le CSS
import '../style/questions.scss'

//On importe le SVG
import Add from '../images/add.svg'
import Moins from '../images/moins.svg'
import France from '../images/france.svg'
import Foret from '../images/foret.svg'
import FeudeBois from '../images/feu-de-bois.svg'


export default function Questions() {

    const data = [
        {
            titre: "Pourquoi votre bois de chauffage est à <span>faible impact</span> ?",
            texte: `<p align="justify">Afin de contrer la montée des températures mondiales, notre engagement est clair : minimiser notre impact sur l'environnement. Le bois, en tant que ressource énergétique, est souvent vu comme neutre. Les jeunes arbres absorbent le CO2 émis lorsque nous le brûlons.<br><br>Mais ne nous y trompons pas, la préparation du bois a son propre coût carbone. Avec notre méthode de séchage naturel, nous évitons d'ajouter à cette empreinte. En choisissant un approvisionnement local <img src=${France} width="25px" alt="drapeau français"/> et en refusant d'utiliser du gaz ou du bois pour accélérer le séchage, nous sommes fidèles à nos valeurs.</p>`,
            icone: Add
        },
        {
            titre: "Comment est séché le bois de chauffage en <span>France</span> ?",
            texte: "<p align=\"justify\">Face aux nouvelles normes législatives, dès le 1er septembre 2023, le bois de chauffage ne pourra avoir une humidité <span>supérieure à 23%</span> sur masse brute pour les ventes en volume jusqu'à 2m3. Moins d'humidité signifie moins de particules fines dans l'air, un bénéfice pour tous.<br><br>Mais cette règle a engendré une tendance : l'<span>augmentation du séchage artificiel</span>, utilisant le gaz ou du bois. Cette méthode augmente drastiquement l'empreinte carbone. Nous, chez Minucci, nous y refusons. Notre choix, ancré depuis 1979, reste le séchage naturel.</p>",
            icone: Add
        },
        {
            titre: "Votre bois de chauffage est-il <span>sec</span> ?",
            texte: `<p align="justify">Bien que nous laissions au soleil et au vent, le soin de prendre en main le séchage de notre bois, nous veillons à ce qu'il atteigne le seuil optimal de <span>23% d'humidité sur masse brute maximum</span>.
            <br><br>
            Notre bois est <span>naturellement sec</span>. pas d'accélération artificielle, simplement la patience et le respect de la nature.
            <br><br>
            Lorsque vous brûlez notre bois, vous pouvez être assuré qu'il est parfaitement sec et que son séchage n'a eu qu'un <span>impact faible</span> sur l'environnement.</p>`,
            icone: Add
        },
        {
            titre: "Quelles sont les <span>essences de bois</span> ?",
            texte: `<p align="justify">Le bois contenu dans nos produits est le reflet de la forêt Françaises <img src=${Foret} width="25px" alt="drapeau français"/>. Les feuillus couvrent la majorité de la superficie de la forêt. Vous trouverez donc <span>exclusivement des essences de bois feuillus</span>.</p><p align="justify">Nous ne pratiquons aucune sélection des essences de bois. C’est pourquoi notre bois de chauffage est composé à <span>95% d’essences de bois dur</span> comme le hêtre, frêne, chêne, charme, sycomaure (erable) et à <span>5% d’essences de bois tendres</span> comme le bouleau, peuplier, etc...</p>`,
            icone: Add
        }
    ]

    //On stock de numéro d'index dans le state
    const [currentIndex, setCurrentIndex] = useState(0)


    return (
        <div id="questions" className="MainQuestions">

            <div className="TitreQuestions">

                <h3 align="center">NOTRE <span>APPROCHE</span></h3> <img src={FeudeBois} alt="feu de bois" width="40px"/>


            </div>

            <div className="ContainerQuestions" >

                {
                    data.map((item, index) => {

                        return (

                            <div key={index} className="ItemQuestions">

                                {/* Quand on clique, on récupère le numéro d'index dans currentIndex via setCurrentIndex*/}
                                <button onClick={() => setCurrentIndex(index)}
                                    className="SujetQuestions">
                                    <h4 dangerouslySetInnerHTML={{ __html: item.titre }}></h4>

                                    {/* On vérifie le numéro d'index pour afficher le - ou le + */}
                                    {
                                        currentIndex === index ? <img src={Moins} alt="icone" width="20px" /> : <img src={Add} alt="icone" width="20px" />
                                    }

                                </button>

                                {/* On vérifie le numéro d'index pour savoir si on affiche le paragraphe ou si on ne l'affiche pas */}
                                {
                                    <p className={currentIndex !== index ? "Hide" : "Hide Display" } dangerouslySetInnerHTML={{ __html: item.texte }}></p>

                                }

                                <div className="SeparateurQuestions"></div>

                            </div>

                        )


                    })
                }

            </div>



        </div>
    )
}