import * as React from "react"
import { Helmet, HelmetProvider } from 'react-helmet-async'



//On importe les images
import Favicon from '../images/favicon.jpg'

//On importe les composants
import Header from '../components/header'
import Mission from '../components/mission'
import Produits from '../components/produits'
import Questions from '../components/questions'
import QuiSommesNous from '../components/qui-sommes-nous'
import Adresse from '../components/adresse'
import Footer from '../components/footer'


//On importe le CSS
import '../style/main.scss'
import '../style/index.scss'
import '../style/formulaire.scss'
import '../style/footer.scss'


const IndexPage = () => {

  const Meta = {
    title: "Minucci - Bois de chauffage à Faible Impact !",
    description: "Le chauffage au bois permet de limiter nos rejets de CO2 dans l'atmosphère. Pour cela, encore faut-il le couper, le façonner, le sécher, l'emballer en minimisant notre impact sur l'environnement.",
    canonical: "https://minucci.fr/"
  }


  return (
    <HelmetProvider>
      <Helmet>
        {/* A reprendre sur toutes les pages */}
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <link rel="icon" href={Favicon} />

        {/* A personnaliser sur toutes les pages */}
        <title>{Meta.title}</title>
        <meta name="description" content={Meta.description} />
        <link rel="canonical" href={Meta.canonical} />
      </Helmet>

      <div className="BackGroundIndex">

      <Header />

      <Mission />


      </div>

      <Produits/>

      <Questions />

      <QuiSommesNous/>

      <Adresse />

      <Footer />




      </HelmetProvider>
  )
}

export default IndexPage