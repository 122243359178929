import * as React from "react"

//On importe les images
import BoxConsigne from '../images/box-consigne.png'
import PaletteBuches from '../images/palette-buches.png'
import AllumeFeu from '../images/allume-feu.png'
import PackBuches from '../images/pack-buches.png'

//On importe le CSS
import '../style/produits.scss'


export default function Produits() {

    const data = [
        {
            image: BoxConsigne,
            width:"150px",
            nom: "Box consigné",
            description: "Un emballage consigné réutilisable à l’infini ou presque.",
            lien: "box-consigne"
        },
        {
            image: PaletteBuches,
            width:"150px",
            nom: "Palette perdue",
            description: "Un emballage en bois jetable pour vous faciliter la livraison.",
            lien: "palette-perdue"
        },
        {
            image: AllumeFeu,
            width:"150px",
            nom: "Allume-feu",
            description: "Pour ceux qui en ont marre du papier journal et du petit bois.",
            lien: "allume-feu"
        },
        {
            image: PackBuches,
            width:"150px",
            nom: "Pack de bûches",
            description: "Aussi pratique qu’un pack d’eau mais avec des bûches.",
            lien: "pack-buches"
        },
    ]

    return (
        <div className="BackProduits">


            <div className="MainProduits">

                {
                    data.map((item, i) => {

                        return (
                            <div key={i} className="ContainerProduits">
                                <a href={item.lien}><img src={item.image} alt={item.nom} width={item.width} /></a>
                                <h2>{item.nom}</h2>
                                <p align="justify">{item.description}</p>
                                <a href={item.lien}>En savoir +</a>
                            </div>
                        )
                    })
                }

            </div>


        </div>
    )
}


