import * as React from "react"


//On importe les composants
import Formulaire from './formulaire'


//On importre le CSS
import '../style/adresse.scss'

//On importe les SVG
import PhoneBlack from '../images/telephone.svg'



export default function Adresse() {


    return (
        <div className="FondSecondary">

            <div className="AdresseMain">

                <div className="AdresseContainer">

                    <div className="AdresseMainCol">
                        <h3>S.A.S. MINUCCI</h3>

                        <ul>
                            <li>31 rue du Général de Gaulle</li>
                            <li>02500 MONDREPUIS</li>
                        </ul>

                        <p align="center"><a href="tel:+33323582966"><img src={PhoneBlack} alt="Téléphone" width="20px" />03 23 58 29 66</a></p>
                        <p align="center">Ouvert du lundi au samedi de 7H00 à 18h00</p>
                    </div>

                    <div className="AdresseMainCol">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10267.907240942064!2d4.0491522!3d49.9555375!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x11cff6170403ffdf!2sMinucci!5e0!3m2!1sfr!2sfr!4v1660120114610!5m2!1sfr!2sfr" title="carte" width="90%" height="340px" style={{ border: 0 }} allowfullscreen="" loading="lazy"></iframe>
                    </div>



                </div>

                <div className="AdresseForm">

                    <Formulaire />

                </div>


            </div>

        </div>
    )
}