import React from "react"


//On importre le CSS
import '../style/qui-sommes-nous.scss'

//On importe les images
import PetitLogoMinucci from '../images/petit-logo-minucci.png'
import DrapeauFrance from '../images/france.svg'


export default function QuiSommesNous() {


    return (
        <div className="BackQuiSommesNous">

            <div className="MainQuiSommesNous">

                <div className="ContainerQuiSommesNous">

                    <img src={PetitLogoMinucci} alt="Logo Minucci" width="150px"/>

                </div>

                <div className="ContainerQuiSommesNous">

                    <h3 align="center">Qui sommes-nous ?</h3>

                    <p align="justify">Derrière ce nom d’origine Italienne se trouve une petite entreprise Française Familiale située à Mondrepuis dans le département de l’Aisne, au coeur de la Forêt.</p>

                    <p align="justify">Nous ne dépendons d’aucun grand groupe, pas d’actionnaires. L’entreprise appartient aux deux frères qui se sont associés. Ils habitent et paient leurs impôts en France.</p>

                    <p align="justify">L’entreprise a été fondée en 1979 par Bartoloméo MINUCCI et ses fils à Rocroi dans les Ardennes, grand département forestier. L’activité était de l’exploitation forestière. Elle achetait du bois sur pied, le coupait et livrait les nombreuses industries dans le secteur en France, Belgique, Italie...</p>

                    <p align="justify">En 1997, l’entreprise déménage à quelques kilomètres dans l’Aisne à Mondrepuis, où elle se situe aujourd’hui.</p>

                    <p align="justify">En 1999, une tempête s’abat sur l’est de la France. Les forêts sont ravagées. Le marché du bois s’effondre, c’est également l’époque des nombreuses délocalisations dans l’industrie. L’entreprise doit évoluer ou disparaître. Le choix est fait de se spécialiser dans la production de bois de chauffage.</p>

                </div>

                <div className="ContainerQuiSommesNous">

                    <img src={DrapeauFrance} alt="drapeau de la france" width="100px"/>

                    <p align="center">BOIS FRANÇAIS</p>


                </div>

            </div>

        </div>
    )
}