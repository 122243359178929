import * as React from "react"

//On importe les images
import Buches from "../images/buches.png"

//On importe le CSS
import '../style/mission.scss'


export default function Mission() {



    return (
        <div className="MainMission">

            <div className="ContainerMission">

                <h1 align="left">BOIS DE CHAUFFAGE À FAIBLE <span>IMPACT !</span></h1>

                <p align="justify">Depuis 1979, notre bois de chauffage sèche naturellement sous le soleil et le vent, protégé de la pluie. Nous pourrions hâter ce processus, mais nous préférons réduire notre empreinte carbone.</p>
                
                <p>Ne réduisons pas le temps de séchage, <b>réduisons notre empreinte</b>.</p>

                <a href="#questions">En savoir +</a>

            </div>

            <div className="ContainerMission">

                <img src={Buches} alt="bûche de bois" width="500px" />

            </div>



        </div>
    )
}


